import * as React from "react";
import { css, useTheme } from "@delight-js/react";

import Grid from "./layout/Grid";
import Row from "./layout/Row";
import Cell from "./layout/Cell";
import { Overline, H1, Subtitle1 } from "./texts";
import ShareMenu from "./ShareMenu";
// import AlignEvenly from "./AlignEvenly";

const PageHeader = ({ overline, headline, subline, url }) => {
  const theme = useTheme();
  return (
    <Grid
      css={css`
      $py14;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
    `(theme)}
    >
      <Row
        css={css(`
    min-height: {160, 160,,,,384}({"step": 8, "method": "round"})px;`)(theme)}
      >
        <Cell
          span={{ default: 10 / 12 }}
          offset={{ default: 1 / 12 }}
          css={css(`
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        `)()}
        >
          {!!overline && (
            <Overline css={css`$mb1;`(theme)} as="h4">
              {overline}
            </Overline>
          )}
          <H1
            as="div"
            css={css`
              max-width: 1050px;
            `}
          >
            <h1
              css={css`
                display: inline;
                font: inherit;
              `}
            >
              {/* <AlignEvenly text={headline} /> */}
              {headline}
            </h1>
            {!!url && (
              <div
                css={css`
                  display: inline-block;
                  position: relative;
                  left: 0;
                  top: 0;
                  width: 0;
                `}
              >
                {"\u00a0"}
                <ShareMenu
                  url={url}
                  css={css`
                    position: absolute;
                    left: 5px;
                    display: block;
                    top: calc(50% - 24px);
                    @media:md {
                      top: calc(50% - 32px);
                    }
                  `}
                />
              </div>
            )}
          </H1>

          {!!url && (
            <ShareMenu
              url={url}
              css={css`
                  display: none;

                  @media:xl {
                    display: block;
                    position: fixed;
                    left: 5px;
                    bottom: 5px;
                    z-index: 999999;
                  `(theme)}
            />
          )}
        </Cell>
      </Row>
      {!!subline && (
        <Row>
          <Cell
            span={{ default: 10 / 12 }}
            offset={{ default: 1 / 12 }}
            css={css`
              position: relative;
            `(theme)}
          >
            <Subtitle1
              css={css`
              $pt4;
              color: var(--color-spot1);
              `(theme)}
            >
              {subline}
            </Subtitle1>
          </Cell>
        </Row>
      )}
    </Grid>
  );
};

export default PageHeader;
